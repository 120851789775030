/* eslint no-console: 0 */


document.addEventListener('turbolinks:load', () => {

    window.queryForPercentage = function(){
        job_id = $('#job_id').text();
        window.query_nbcalls +=1;
        $.ajax({
            url: "/percentage_done",
            data: {
                job_id: job_id
            },
            success: function(data){
                console.log(data);
                current = data['percentage_done'];
                status = data['status'];
                percentage = "width: " + current +"%";
                $('#job-progress').attr('style', percentage).text(current+"%");
                if (status === "working"){
                    return setTimeout(queryForPercentage, 1500) ;
                } else if (status == "failed"){

                    $('#job-progress').attr('style', "width: 100%").text( "erreur serveur ");
                }
                else if (status === "complete") {
                        location.reload();

                };
                return null  ;
            }
        });
    };
});
